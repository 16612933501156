import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation"; // Import the navigation CSS
import img1 from "./imgs/services/canva1.png";
import img2 from "./imgs/services/canva2.png";
import img3 from "./imgs/services/canva3.png";
import img4 from "./imgs/services/canva4.png";
import './services.css';
import {
  ForkOutlined,
  HeartOutlined,
  BankOutlined,
  TeamOutlined,
  GiftOutlined,
  CrownOutlined,
  FileTextOutlined,
  ShoppingOutlined,
  CarOutlined,
  FormOutlined,
} from "@ant-design/icons";

const Home = () => {
  return (
    <>
      <div id="course" className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <h1 className="text-hovered font-bold text-3xl mb-2 text-center">
          Services <span className="text-bg">We Offer</span>
        </h1>
        <div className="flex flex-cols-2 max-w-7xl mx-auto px-2 sm:px-4 lg:px-6 py-4 flex-wrap transition-all">
          <div
            className="mx-auto border-2 border-opacity-80 mt-8 grid justify-items-center text-center 
  rounded-xl shadow-xl hover:scale-105 hover:rotate-6 hover:transform hover:transition-transform 
  duration-300 ease-in-out p-4"
          >
            <div className="rounded-full bg-hovered p-4 w-[3.5rem] flex items-center justify-center">
              <ForkOutlined className="text-bg" />
            </div>

            <h1 className="text-bg text-2xl font-bold mt-4">Dine-in Meals</h1>

            <p className="mb-8 mx-8">
              Enjoy fresh, delicious meals in our comfortable restaurant.
            </p>
          </div>

          <div
            className="mx-auto border-2 border-opacity-80 mt-8 grid justify-items-center text-center 
  rounded-xl shadow-xl hover:scale-105 hover:rotate-[-6deg] hover:transform hover:transition-transform 
  duration-300 ease-in-out p-4"
          >
            <div className="rounded-full bg-hovered p-4 w-[3.5rem] flex items-center justify-center">
              <HeartOutlined className="text-bg" />
            </div>
            <h1 className="text-bg text-2xl font-bold mt-4">
              Catering for Weddings
            </h1>
            <p className="mb-8 mx-8">
              Special food services for weddings, tailored to your needs.
            </p>
          </div>

          <div
            className="mx-auto border-2 border-opacity-80 mt-8 grid justify-items-center text-center 
  rounded-xl shadow-xl hover:scale-105 hover:rotate-6 hover:transform hover:transition-transform 
  duration-300 ease-in-out p-4"
          >
            <div className="rounded-full bg-hovered p-4 w-[3.5rem] flex items-center justify-center">
              <BankOutlined className="text-bg" />
            </div>
            <h1 className="text-bg text-2xl font-bold mt-4">
              Catering for Corporate Events
            </h1>
            <p className="mb-8 mx-8">
              Food for company events, meetings, and gatherings.
            </p>
          </div>

          <div
            className="mx-auto border-2 border-opacity-80 mt-8 grid justify-items-center text-center 
  rounded-xl shadow-xl hover:scale-105 hover:rotate-[-6deg] hover:transform hover:transition-transform 
  duration-300 ease-in-out p-4"
          >
            <div className="rounded-full bg-hovered p-4 w-[3.5rem] flex items-center justify-center">
              <TeamOutlined className="text-bg" />
            </div>
            <h1 className="text-bg text-2xl font-bold mt-4">
              Catering for Family Events
            </h1>
            <p className="mb-8 mx-8">
              Family-focused meals for birthdays, reunions, and other
              gatherings.
            </p>
          </div>

          <div
            className="mx-auto border-2 border-opacity-80 mt-8 grid justify-items-center text-center 
  rounded-xl shadow-xl hover:scale-105 hover:rotate-6 hover:transform hover:transition-transform 
  duration-300 ease-in-out p-4"
          >
            <div className="rounded-full bg-hovered p-4 w-[3.5rem] flex items-center justify-center">
              <GiftOutlined className="text-bg" />
            </div>
            <h1 className="text-bg text-2xl font-bold mt-4">
              Catering for Special Ceremonies
            </h1>
            <p className="mb-8 mx-8">
              Quality food for graduations, memorials, and other important
              ceremonies.
            </p>
          </div>

          <div
            className="mx-auto border-2 border-opacity-80 mt-8 grid justify-items-center text-center 
  rounded-xl shadow-xl hover:scale-105 hover:rotate-[-6deg] hover:transform hover:transition-transform 
  duration-300 ease-in-out p-4"
          >
            <div className="rounded-full bg-hovered p-4 w-[3.5rem] flex items-center justify-center">
              <CrownOutlined className="text-bg" />
            </div>
            <h1 className="text-bg text-2xl font-bold mt-4">
              Anniversary Catering
            </h1>
            <p className="mb-8 mx-8">
              Celebrate anniversaries with custom catering menus.
            </p>
          </div>

          <div
            className="mx-auto border-2 border-opacity-80 mt-8 grid justify-items-center text-center 
  rounded-xl shadow-xl hover:scale-105 hover:rotate-6 hover:transform hover:transition-transform 
  duration-300 ease-in-out p-4"
          >
            <div className="rounded-full bg-hovered p-4 w-[3.5rem] flex items-center justify-center">
              <FileTextOutlined className="text-bg" />
            </div>
            <h1 className="text-bg text-2xl font-bold mt-4">
              Event Planning Assistance
            </h1>
            <p className="mb-8 mx-8">
              We help plan your event, from food to setup.
            </p>
          </div>

          <div
            className="mx-auto border-2 border-opacity-80 mt-8 grid justify-items-center text-center 
  rounded-xl shadow-xl hover:scale-105 hover:rotate-[-6deg] hover:transform hover:transition-transform 
  duration-300 ease-in-out p-4"
          >
            <div className="rounded-full bg-hovered p-4 w-[3.5rem] flex items-center justify-center">
              <ShoppingOutlined className="text-bg" />
            </div>
            <h1 className="text-bg text-2xl font-bold mt-4">Take-out Orders</h1>
            <p className="mb-8 mx-8">
              Quick meals to go, ready for pick-up anytime.
            </p>
          </div>

          <div
            className="mx-auto border-2 border-opacity-80 mt-8 grid justify-items-center text-center 
  rounded-xl shadow-xl hover:scale-105 hover:rotate-6 hover:transform hover:transition-transform 
  duration-300 ease-in-out p-4"
          >
            <div className="rounded-full bg-hovered p-4 w-[3.5rem] flex items-center justify-center">
              <CarOutlined className="text-bg" />
            </div>
            <h1 className="text-bg text-2xl font-bold mt-4">Food Delivery</h1>
            <p className="mb-8 mx-8">
              We deliver delicious food straight to your door.
            </p>
          </div>

          <div
            className="mx-auto border-2 border-opacity-80 mt-8 grid justify-center items-center text-center 
   rounded-xl shadow-xl hover:scale-105 hover:rotate-[-6deg] hover:transform hover:transition-transform duration-300 ease-in-out p-4"
          >
            <div className="rounded-full bg-hovered p-4 w-[3.5rem] flex items-center justify-center mx-auto">
              <FormOutlined className="text-bg" />
            </div>

            <h1 className="text-bg text-2xl font-bold text-center mt-4">
              Custom Menus
            </h1>
            <br />
            <p className="mb-8 mx-8">
              Create your own meal menu for any event or special occasion.
            </p>
          </div>
        </div>

        <div className="relative w-full h-screen">
          <div className="absolute inset-0 z-0">
            <Swiper
              modules={[Pagination, Autoplay, Navigation]}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ clickable: true }}
              autoplay={{ delay: 3000 }}
              speed={1500}
              loop={true}
              navigation // Add navigation
            >
              <SwiperSlide>
                <img
                  src={img1}
                  alt="Slide 1"
                  className="w-full object-cover"
                  style={{ height: "14cm" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={img2}
                  alt="Slide 2"
                  className="w-full object-cover"
                  style={{ height: "14cm" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={img3}
                  alt="Slide 3"
                  className="w-full object-cover"
                  style={{ height: "14cm" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={img4}
                  alt="Slide 4"
                  className="w-full object-cover"
                  style={{ height: "14cm" }}
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="relative z-10 flex flex-col lg:flex-row max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 justify-center items-center text-center ">
          <div
            className="lg:w-1/2 lg:pr-8 col1 text-white justify-center hidden lg:block md:block
             text-center component"
            style={{ marginTop: "-15rem" }}
          >
            <Swiper
              modules={[Autoplay]}
              spaceBetween={80}
              slidesPerView={2}
              autoplay={{ delay: 3000 }}
              speed={1500}
              loop={true}
              className=""
            >
              <SwiperSlide>
                <div className="bg-white mx-5 border-2  w-64 shadow-xl rounded-xl hover:translate-2 py-11 px-2 mt-16">
                  <h2 data-aos="fade-up" className="text-bg font-bold text-xl">
                    Dine-in Experience
                  </h2>
                  <h3 className="text-hovered mx-4 mt-4">
                    Get top-notch dining in a stylish setting.
                  </h3>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-white mx-5 border-2  w-64 shadow-xl rounded-xl hover:translate-2 py-11 px-2 mt-16">
                  <h2 data-aos="fade-up" className="text-bg font-bold text-xl">
                    Catering Services
                  </h2>
                  <h3 className="text-hovered mx-4 mt-4">
                    Custom food for weddings, corporate events, family
                    gatherings, special ceremonies, and anniversaries.
                  </h3>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-white mx-5 border-2  w-64 shadow-xl rounded-xl hover:translate-2 py-11 px-2 mt-16">
                  <h2 data-aos="fade-up" className="text-bg font-bold text-xl">
                    Take-Out and Delivery
                  </h2>
                  <h3 className="text-hovered mx-4 mt-4">
                    Convenient take-out meals and delivery straight to your
                    door.
                  </h3>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-white mx-5 border-2  w-64 shadow-xl rounded-xl hover:translate-2 py-11 px-2 mt-16">
                  <h2 data-aos="fade-up" className="text-bg font-bold text-xl">
                    Event Planning Assistance
                  </h2>
                  <h3 className="text-hovered mx-4 mt-4">
                    Help with planning and organizing your events, from setup to
                    custom menus.
                  </h3>
                </div>
                {/* feedback fixed */}
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
