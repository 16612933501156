/** @format */

// src/App.js

import React, { useEffect } from "react";
import Header from "./parts/header";
import Home from "./parts/home";
import AboutUs from "./parts/aboutus";
import Mission from "./parts/mission";
import Vision from "./parts/vision";
import Services from "./parts/services.jsx";
import Footer from "./parts/footer.jsx";
import AOS from "aos";
import "aos/dist/aos.css";
import "./App.css";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
    });
  }, []);

  return (
    <>
      <Header />
      <div className="App">
        <section id="home">
          <Home />
        </section>
        <section id="aboutus">
          <AboutUs />
        </section>
        <section id="mission">
          <Mission />
        </section>
        <section id="vision">
          <Vision />
        </section>
        <section id="services">
          <Services />
        </section>
        <section id="contact">
          <Footer />
        </section>
      </div>
    </>
  );
}

export default App;
