// src/parts/Header.jsx

import React, { useState } from "react";
import { Link } from "react-scroll"; // Import Link from react-scroll
import logo from './imgs/home/Logo.png';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-white text-black pt-8 sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex-shrink-0">
            <a href="#home" className="text-2xl font-bold text-hovered">
              <img className="w-24 h-auto" src={logo} alt="Honex Logo" />
            </a>
          </div>

          {/* Navigation Links with react-scroll */}
          <nav className="hidden md:flex space-x-8">
            <Link
              to="home"
              smooth={true}
              duration={500}
              offset={-100}
              className="cursor-pointer hover:text-hovered"
            >
              Home
            </Link>
            <Link
              to="aboutus"
              smooth={true}
              duration={500}
              offset={-100}
              className="cursor-pointer hover:text-hovered"
            >
              About Us
            </Link>
            <Link
              to="mission"
              smooth={true}
              duration={500}
              offset={-100}
              className="cursor-pointer hover:text-hovered"
            >
              Mission
            </Link>
            <Link
              to="vision"
              smooth={true}
              duration={500}
              offset={-100}
              className="cursor-pointer hover:text-hovered"
            >
              Vision
            </Link>
            <Link
              to="services"
              smooth={true}
              duration={500}
              offset={-100}
              className="cursor-pointer hover:text-hovered"
            >
              Services
            </Link>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              offset={-100}
              className="cursor-pointer hover:text-hovered"
            >
              Contact
            </Link>
          </nav>

          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-black focus:outline-none"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden fixed inset-0 bg-bg py-0">
          <div className="flex justify-between items-center h-16">
            <div className="flex-shrink-0 bg-white">
              <a href="#home" className="text-2xl font-bold text-hovered">
                <img className="w-24 h-auto" src={logo} alt="Honex Logo" />
              </a>
            </div>

            {/* Navigation Links with react-scroll */}
            <nav className="hidden md:flex space-x-8">
              <Link
                to="home"
                smooth={true}
                duration={500}
                offset={-100}
                className="cursor-pointer hover:text-hovered"
              >
                Home
              </Link>
              <Link
                to="aboutus"
                smooth={true}
                duration={500}
                offset={-100}
                className="cursor-pointer hover:text-hovered"
              >
                About Us
              </Link>
              <Link
                to="mission"
                smooth={true}
                duration={500}
                offset={-100}
                className="cursor-pointer hover:text-hovered"
              >
                Mission
              </Link>
              <Link
                to="vision"
                smooth={true}
                duration={500}
                offset={-100}
                className="cursor-pointer hover:text-hovered"
              >
                Vision
              </Link>
              <Link
                to="services"
                smooth={true}
                duration={500}
                offset={-100}
                className="cursor-pointer hover:text-hovered"
              >
                Services
              </Link>
              <Link
                to="contact"
                smooth={true}
                duration={500}
                offset={-100}
                className="cursor-pointer hover:text-hovered"
              >
                Contact
              </Link>
            </nav>

            <div className="md:hidden">
              <button
                onClick={toggleMenu}
                className="text-black focus:outline-none"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={
                      isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"
                    }
                  />
                </svg>
              </button>
            </div>
          </div>
          <nav className="flex flex-col justify-center items-center text-center gap-8 h-screen text-xl">
            <Link
              to="home"
              smooth={true}
              duration={500}
              offset={-1000}
              className="block text-white cursor-pointer"
              onClick={() => setIsOpen(false)}
            >
              Home
            </Link>
            <Link
              to="aboutus"
              smooth={true}
              duration={500}
              offset={-1000}
              className="block px-4 py-2 text-white hover:bg-bg cursor-pointer "
              onClick={() => setIsOpen(false)}
            >
              About Us
            </Link>
            <Link
              to="mission"
              smooth={true}
              duration={500}
              offset={-1000}
              className="block px-4 py-2 text-white hover:bg-bg cursor-pointer "
              onClick={() => setIsOpen(false)}
            >
              Mission
            </Link>
            <Link
              to="vision"
              smooth={true}
              duration={500}
              offset={-1000}
              className="block px-4 py-2 text-white hover:bg-bg cursor-pointer "
              onClick={() => setIsOpen(false)}
            >
              Vision
            </Link>
            <Link
              to="services"
              smooth={true}
              duration={500}
              offset={-1000}
              className="block px-4 py-2 text-white hover:bg-bg cursor-pointer "
              onClick={() => setIsOpen(false)}
            >
              Services
            </Link>
            <Link
              to="contact"
              smooth={true}
              duration={500}
              offset={-1000}
              className="block px-4 py-2 text-white hover:bg-bg cursor-pointer "
              onClick={() => setIsOpen(false)}
            >
              Contact
            </Link>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
