import React from "react";
import { motion } from "framer-motion";

const Vision = () => {
  return (
    <section className="container mx-auto py-2 px-4">
      <div className="flex flex-col lg:flex-row lg:space-x-8">
        {/* Vision Card 1 */}
        <motion.div
          data-aos="fade-right"
          className="flex flex-col items-center  p-8  justify-center border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
          whileHover={{ scale: 1.05 }}
        >
          <h2 className="text-center text-bg text-4xl font-bold mb-12">
            Our <span className="text-hovered">Vision</span>
          </h2>

          <p className="mb-6 sm:px-2 lg:px-20 md:px-20">
            To be the premier choice for catering services, renowned for our
            exceptional culinary creations, impeccable service, and unwavering
            commitment to customer satisfaction while maintaining our core
            values of honesty and excellence.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default Vision;

