// AboutUs.js
import React from "react";
import Team from "./team"

export default function AboutUs() {
    return (
      <>
          <Team />
      </>
    );
}
