// src/parts/Home.jsx

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectFade} from "swiper/modules";
import "swiper/css/effect-fade";
import "swiper/css/effect-cube";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import img1 from "./imgs/home/2canva.png";
import img2 from "./imgs/home/2.jpeg";
import img3 from "./imgs/home/3.jpeg";
import img4 from "./imgs/home/4.jpeg";
import "aos/dist/aos.css";

const Home = () => {
  return (
    <section
      id="course"
      className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-4"
    >
      {/* Flex container for the two columns */}
      <div className="flex flex-col lg:flex-row ">
        {/* First Column: Text Content */}
        <div className="relative w-full h-screen">
          {/* Swiper as the background */}
          <div className="absolute inset-0 z-0">
            <Swiper
              modules={[Pagination, Autoplay, EffectFade]}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ clickable: true }}
              autoplay={{ delay: 3000 }}
              speed={1500}
              loop={true}
              effect="fade" // Set the fade effect
            >
              <SwiperSlide>
                <img
                  src={img1}
                  alt="Slide 1"
                  className="w-full object-cover"
                  style={{ height: "14cm" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={img2}
                  alt="Slide 2"
                  className="w-full object-cover"
                  style={{ height: "14cm" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={img3}
                  alt="Slide 3"
                  className="w-full object-cover"
                  style={{ height: "14cm" }}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={img4}
                  alt="Slide 4"
                  className="w-full object-cover"
                  style={{ height: "14cm" }}
                />
              </SwiperSlide>
            </Swiper>
          </div>

          {/* Foreground Text Content */}
          <div className="relative z-10 flex flex-col lg:flex-row max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
            {/* First Column: Text Content */}
            <div className="lg:w-1/2 md:w-1/2 sm:w-1/2  lg:px-16 col1 text-white shadow-xl  py-8 px-8 rounded-2xl mt-56 bg-[#ffffffad]">
              {/* Text Swiper */}
              <h4
                data-aos="fade-up"
                className="lg:text-3xl sm:text-2xl text-bg font-bold mb-4"
              >
                <span className="text-hovered">Welcome To</span> <br />
                HONEX FOOD CENTER Ltd
              </h4>
              <Swiper
                modules={[Autoplay, EffectFade]}
                spaceBetween={50}
                slidesPerView={1}
                autoplay={{ delay: 3000 }}
                speed={1500}
                loop={true}
              >
                <SwiperSlide>
                  <h2 data-aos="fade-up" className="text-hovered font-bold">
                    Dine-in Experience
                  </h2>
                  <h3 className="text-hovered font-gray-200">
                    Get top-notch dining in a stylish setting.
                  </h3>
                </SwiperSlide>
                <SwiperSlide>
                  <h2 data-aos="fade-up" className="text-hovered font-bold">
                    Catering Services
                  </h2>
                  <h3 className="text-hovered font-gray-200">
                    Get tailored catering for all your special occasions.
                  </h3>
                </SwiperSlide>
                <SwiperSlide>
                  <h2 data-aos="fade-up" className="text-hovered font-bold">
                    Take-out and Delivery
                  </h2>
                  <h3 className="text-hovered font-gray-200">
                    Get delicious meals delivered right to you.
                  </h3>
                </SwiperSlide>
                {/* Add more slides as needed */}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
