import "aos/dist/aos.css";
import './team.css';

export default function Team() {
  return (
    <div
      className="mb-32 aboutus container"
    >
      <div className="mx-auto grid max-w-7xl gap-x-4 gap-y-20 px-6 lg:px-8 justify-center items-center  cols-1 lg:mt-14 sm:mt-5">
        <div data-aos="fade-up" className="max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-bg sm:text-4xl text-center">
            About Us
          </h2>
          <p className="mt-6 leading-8">
            Welcome to HONEX FOOD CENTER (HFC), where culinary excellence meets
            exceptional service. Nestled in the heart of Gahanga next to MTN
            Shop in Kicukiro District, our restaurant has been a beloved
            destination for food lovers who crave fresh, flavorful dishes made
            with the finest ingredients. But we don’t just serve great food in
            our restaurant—we bring the experience to you. Our catering services
            are designed to make every event, big or small, a memorable culinary
            journey. Whether you're hosting an intimate gathering, a corporate
            event, or a grand celebration, our team is dedicated to crafting a
            personalized menu that reflects your taste and exceeds your
            expectations. ‌
          </p>
        </div>
      </div>
    </div>
  );
}
