import React from "react";
import {
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import "./Footer.css"; // Custom styles

const Footer = () => {
  return (
    <footer className="text-white contactus">
      <div className="bg-hovered grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-8">
        {/* Contact Section */}
        <div className="md:mb-0 grid justify-center items-center text-center lg:text-left px-6">
          <h3 className="text-xl text-bg text-center font-bold p-4">Contact Us</h3>
          <ul className="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-6">
            <li className="flex items-center justify-center lg:justify-start hover:text-bg p-2">
              <PhoneOutlined className="mr-2 hover:text-bg" />
              <span className="hover:text-bg">+250787561177/ +250732478400</span>
            </li>
            <li className="flex items-center justify-center lg:justify-start hover:text-bg p-2">
              <MailOutlined className="mr-2 hover:text-bg" />
              <span className="hover:text-bg">honexfoodcenter@gmail.com</span>
            </li>
            <li className="flex items-center justify-center lg:justify-start hover:text-bg p-2">
              <EnvironmentOutlined className="mr-2 hover:text-bg" />
              <span className="hover:text-bg">Gahanga next to MTN shop (KICUKIRO District)<br/>Kigali, City, Rwanda</span>
            </li>
          </ul>
        </div>

      </div>

      <div className="text-center text-gray-400 lower_footer p-4">
        <p>&copy; 2024 Honex Food Rwanda Ltd. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
